import VueRouter, { RouteConfig } from 'vue-router';
import { ResourceType, Role } from '@/graphql/types';
import { logger } from '@/logger';
import { useAuthStore } from '@/stores/auth';

export const routes: RouteConfig[] = [
  {
    path: '/kitchen-sink',
    name: 'KitchenSink',
    component: () =>
      import(/* webpackChunkName: "KitchenSink" */ '@/views/KitchenSink.vue'),
  },
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore();

      logger.debug('beforeEnter');

      // redirect to home or login depending if JWT is set
      if (authStore.state === 'unauthenticated') {
        return next({
          name: 'Registration',
        });
      }

      return next({
        name: 'Resource',
        params: {
          role: authStore.role?.toLowerCase() ?? '',
          resourceType: authStore.resource?.resource.toLowerCase() ?? '',
          resourceId:
            !authStore.resource && authStore.role === Role.Admin
              ? 'default'
              : authStore.resource?.resourceId ?? '',
        },
      });
    },
  },
  {
    path: '/404',
    name: 'PageNotFound',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound.vue'
      ) as TODO,
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
  },
  {
    path: '/registratie',
    name: 'Registration',
    meta: {
      allowUnauthorized: true,
    },
    redirect: {
      name: 'Order',
    },
    component: () =>
      import(
        /* webpackChunkName: "Registration" */ '@/views/Order/Wrapper.vue'
      ),
    children: [
      {
        path: 'bestellen',
        name: 'Order',
        meta: {
          allowUnauthorized: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/Order/Landing.vue'
          ),
      },
      {
        path: 'bestellen/stap-1',
        name: 'Order - Step 1',
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/Order/Step1.vue'
          ),
        meta: {
          allowUnauthorized: true,
        },
      },
      {
        path: 'bestellen/stap-2',
        name: 'Order - Step 2',
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/Order/Step2.vue'
          ),
        meta: {
          allowUnauthorized: true,
        },
      },
      {
        path: 'bestellen/stap-3',
        name: 'Order - Step 3',
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/Order/Step3.vue'
          ),
        meta: {
          allowUnauthorized: true,
        },
      },
      {
        path: 'bestellen/stap-4',
        name: 'Order - Step 4',
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/Order/Step4.vue'
          ),
        meta: {
          allowUnauthorized: true,
        },
      },
      {
        path: 'bestellen/stap-5',
        name: 'Order - Step 5',
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/Order/Step5.vue'
          ),
        meta: {
          allowUnauthorized: true,
        },
      },
      {
        path: 'bestellen/succes',
        name: 'Order - Success',
        meta: {
          allowUnauthorized: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "Registration" */ '@/views/Order/Success.vue'
          ),
      },
    ],
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPassword.vue'
      ),
  },
  {
    path: '/forgot-username',
    name: 'ForgotUsername',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/ForgotUsername.vue'
      ),
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/NewPassword.vue'
      ),
  },
  {
    path: '/new-user',
    name: 'NewUser',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(/* webpackChunkName: "ForgotPassword" */ '@/views/NewUser.vue'),
  },
  {
    path: '/choose-role',
    name: 'ChooseRole',
    component: () =>
      import(/* webpackChunkName: "ChooseRole" */ '@/views/ChooseRole.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(
        /* webpackChunkName: "UserTypeEnvironment" */ '@/views/UserTypeEnvironment.vue'
      ),
    redirect: {
      name: 'AdminDefault',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
    meta: {
      title: 'Profiel',
      hideBorder: true,
    },
  },
  {
    path: '/profile-edit',
    name: 'ProfileEdit',
    component: () =>
      import(/* webpackChunkName: "Profile" */ '@/views/ProfileEdit.vue'),
    meta: {
      title: 'Profiel',
    },
  },
  {
    path: '/:role/:resourceType/:resourceId',
    name: 'Resource',
    meta: { role: () => useAuthStore().role },
    redirect: {
      name: 'ResourceDashboard',
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UserTypeEnvironment" */ '@/views/UserTypeEnvironment.vue'
      ),
    children: [
      {
        path: 'dashboard',
        name: 'ResourceDashboard',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDashboard" */ '@/views/Default/ResourceDashboard.vue'
          ) as TODO,
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: 'management',
        name: 'ResourceManagement',
        meta: {
          title: 'Beheer',
        },
      },
      /** *****************
       *
       *  STUDENTS
       *
       ***************** */
      {
        path: 'management/students',
        name: 'ResourceManagementStudents',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Default/Students.vue'
          ),
        meta: {
          title: 'Cursisten',
          hideFor: [
            { role: Role.SchoolDistrictManager },
            { context: ResourceType.Partner },
            { role: Role.Admin, context: ResourceType.SchoolDistrict },
          ],
        },
      },
      {
        path: 'management/student-add',
        name: 'ResourceManagementStudentAdd',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Default/StudentAdd.vue'
          ),
        meta: {
          hideFromMenu: true,
          title: 'Cursist toevoegen',
        },
      },
      {
        path: 'management/students-import',
        name: 'ResourceManagementStudentsImport',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Online/StudentsImport.vue'
          ),
        meta: {
          hideFromMenu: true,
          title: 'Cursisten importeren',
        },
      },
      {
        path: 'management/students/:studentId',
        name: 'ResourceManagementStudentsStudent',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Default/Student.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementStudentsStudentData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementStudentsStudentData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'results',
            name: 'ResourceManagementStudentsStudentResults',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentResults.vue'
              ),
            meta: {
              title: 'Resultaten',
            },
          },
          {
            path: 'notes',
            name: 'ResourceManagementStudentsStudentNotes',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentNotes.vue'
              ),
            meta: {
              title: 'Notities',
            },
          },
          {
            path: 'game-settings',
            name: 'ResourceManagementStudentsStudentGameSettings',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/TypetuinLevelSettings.vue'
              ),
            meta: {
              title: 'Spelinstellingen',
            },
          },
          {
            path: 'settings',
            name: 'ResourceManagementStudentsStudentSettings',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentSettings.vue'
              ),
            meta: {
              title: 'Instellingen',
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementStudentsStudentEdit',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentEdit.vue'
              ),
            meta: {
              title: 'Gegevens bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementStudentsStudentEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
          {
            path: 'move-student',
            name: 'ResourceManagementStudentsStudentMove',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentMove.vue'
              ),
            meta: {
              title: 'Student verhuizen',
              hideFromMenu: true,
            },
          },
        ],
      },
      /** *****************
       *
       *  COURSEGROUPS
       *
       ***************** */
      {
        path: 'management/coursegroups',
        name: 'ResourceManagementCourseGroups',
        component: () =>
          import(
            /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroups.vue'
          ),
        meta: {
          title: 'Cursusgroepen',
          hideFor: [
            { role: Role.SchoolDistrictManager },
            { role: Role.Admin, context: ResourceType.SchoolDistrict },
            { context: ResourceType.Partner },
          ],
        },
      },
      {
        path: 'management/coursegroup-add',
        name: 'ResourceManagementCourseGroupAdd',
        component: () =>
          import(
            /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupAddEdit.vue'
          ) as TODO,
        meta: {
          title: 'Cursusgroep toevoegen',
          hideFromMenu: true,
        },
      },
      {
        path: 'management/coursegroups/:courseGroupId',
        name: 'ResourceManagementCourseGroupsCourseGroup',
        component: () =>
          import(
            /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroup.vue'
          ) as TODO,
        props: true,
        meta: {
          title: 'Cursusgroepen',
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementCourseGroupsCourseGroupStudents',
        },
        children: [
          {
            path: 'students',
            name: 'ResourceManagementCourseGroupsCourseGroupStudents',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupStudents.vue'
              ),
            meta: {
              hideFromMenu: false,
              title: 'Cursisten',
            },
          },
          {
            path: 'coaches',
            name: 'ResourceManagementCourseGroupsCourseGroupCoaches',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupCoaches.vue'
              ),
            meta: {
              hideFromMenu: false,
              hideFor: [{ role: Role.Coach }],
              title: 'Coaches',
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementCourseGroupsCourseGroupEdit',
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupAddEdit.vue'
              ) as TODO,
            props: true,
            meta: {
              hideFor: [{ role: Role.Coach }],
              title: 'Beheren',
            },
          },
          {
            path: 'game-settings',
            name: 'ResourceManagementCourseGroupsCourseGroupEditGameSettings',
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/TypetuinLevelSettings.vue'
              ),
            meta: {
              title: 'Spelinstellingen',
            },
          },
          {
            path: 'results',
            name: 'ResourceManagementCourseGroupsCourseGroupResults',
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupResults.vue'
              ),
            meta: {
              title: 'Resultaten',
            },
            redirect: {
              name: 'ResourceManagementCourseGroupsCourseGroupResultsLevels',
            },
            children: [
              {
                path: 'levels',
                name: 'ResourceManagementCourseGroupsCourseGroupResultsLevels',
                props: true,
                meta: {
                  title: 'Levels',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "Coursegroups" */ '@/views/Default/ResultsLevels.vue'
                  ),
              },
              {
                path: 'exams',
                name: 'ResourceManagementCourseGroupsCourseGroupResultsExams',
                component: () =>
                  import(
                    /* webpackChunkName: "Coursegroups" */ '@/views/Default/ResultsExams.vue'
                  ),
                props: true,
                meta: {
                  title: 'Examens',
                },
              },
              {
                path: 'participation',
                name: 'ResourceManagementCourseGroupsCourseGroupResultsParticipation',
                component: () =>
                  import(
                    /* webpackChunkName: "Coursegroups" */ '@/views/Default/ResultsParticipation.vue'
                  ),
                props: true,
                meta: {
                  title: 'Participatie',
                },
              },
            ],
          },
        ],
      },
      /** *****************
       *
       *  COACHES
       *
       ***************** */
      {
        path: 'management/coaches',
        name: 'ResourceManagementCoaches',
        component: () =>
          import(
            /* webpackChunkName: "Coaches" */ '@/views/Default/Coaches.vue'
          ),
        meta: {
          title: 'Coaches',
          hideFor: [
            { role: Role.Coach },
            { role: Role.SchoolDistrictManager },
            { context: ResourceType.Partner },
            { role: Role.Admin, context: ResourceType.SchoolDistrict },
          ],
        },
      },
      {
        path: 'management/coach-add',
        name: 'ResourceManagementCoachAdd',
        component: () =>
          import(
            /* webpackChunkName: "Coaches" */ '@/views/Default/CoachAdd.vue'
          ),
        meta: {
          hideFromMenu: true,
          title: 'Coach Toevoegen',
        },
      },
      {
        path: 'management/coaches/:coachId',
        name: 'ResourceManagementCoachesCoach',
        component: () =>
          import(/* webpackChunkName: "Coaches" */ '@/views/Default/Coach.vue'),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementCoachesCoachData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementCoachesCoachData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'coursegroups',
            name: 'ResourceManagementCoachesCoachGroups',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachCourseGroups.vue'
              ) as TODO,
            meta: {
              title: 'Cursusgroepen',
            },
          },
          {
            path: 'messages',
            name: 'ResourceManagementCoachesMessages',
            props: true,
            meta: {
              hideFromMenu: true,
            },
            redirect: {
              name: 'ResourceManagementCoachesMessagesInbox',
            },
          },
          {
            path: 'message/:messageId',
            name: 'ResourceManagementCoachesMessagesMessage',
            component: () =>
              import(
                /* webpackChunkName: "Messages" */ '@/views/Default/Message.vue'
              ),
            props: true,
            meta: {
              hideFromMenu: true,
            },
          },
          {
            path: 'messages/inbox',
            name: 'ResourceManagementCoachesMessagesInbox',
            component: () =>
              import(
                /* webpackChunkName: "Messages" */ '@/views/Default/MessagesBox.vue'
              ),
            props: true,
            meta: {
              messageBoxType: 'inbox',
              title: 'Berichten Inbox',
              showFor: [{ role: Role.Admin }],
            },
          },
          {
            path: 'messages/sent',
            name: 'ResourceManagementCoachesMessagesSent',
            component: () =>
              import(
                /* webpackChunkName: "Messages" */ '@/views/Default/MessagesBox.vue'
              ),
            props: true,
            meta: {
              messageBoxType: 'sent',
              title: 'Berichten Verzonden',
              showFor: [{ role: Role.Admin }],
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementCoachesCoachEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachEdit.vue'
              ),
            meta: {
              title: 'Bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementCoachesCoachEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
          {
            path: 'settings',
            name: 'ResourceManagementCoachesCoachSettings',
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachSettings.vue'
              ),
            meta: {
              title: 'Instellingen',
            },
          },
          {
            path: 'settings-edit',
            name: 'ResourceManagementCoachesCoachSettingsEdit',
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/Coach/Settings/Edit.vue'
              ),
            meta: {
              title: 'Instellingen aanpassen',
              hideFromMenu: true,
              showFor: [
                {
                  role: Role.Admin,
                },
              ],
            },
          },
        ],
      },
      /** *****************
       *
       *  SCHOOLS
       *
       ***************** */
      {
        path: 'management/schools',
        name: 'ResourceManagementSchools',
        component: () =>
          import(
            /* webpackChunkName: "Schools" */ '@/views/Default/Schools.vue'
          ),
        meta: {
          title: 'Scholen',
          showFor: [
            {
              role: Role.Admin,
              context: Role.Admin,
            },
            {
              role: Role.SchoolDistrictManager,
            },
            {
              context: ResourceType.Partner,
            },
            {
              role: Role.Admin,
              context: ResourceType.SchoolDistrict,
            },
          ],
        },
      },
      {
        path: 'management/school-add',
        name: 'ResourceManagementSchoolAdd',
        component: () =>
          import(
            /* webpackChunkName: "Schools" */ '@/views/Default/SchoolAddEdit.vue'
          ) as TODO,
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'Resource Toevoegen',
        },
      },
      {
        path: 'management/schools/:schoolManageId',
        name: 'ResourceManagementSchoolsSchool',
        component: () =>
          import(
            /* webpackChunkName: "Schools" */ '@/views/Default/School.vue'
          ) as TODO,
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'School',
        },
        redirect: {
          name: 'ResourceManagementSchoolsSchoolData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementSchoolsSchoolData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/SchoolData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementSchoolsSchoolAddEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/SchoolAddEdit.vue'
              ) as TODO,
            meta: {
              title: 'Bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'licenses',
            name: 'ResourceManagementSchoolsSchoolLicenses',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/ResourceLicenses.vue'
              ) as TODO,
            meta: {
              title: 'Licenties',
            },
          },
          {
            path: 'manage-subscription',
            name: 'ResourceManagementSchoolsSchoolManageSubscription',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/ResourceManageSubscription.vue'
              ),
            meta: {
              title: 'Abonnement beheren',
              hideFromMenu: true,
            },
          },
          // {
          //   path: 'order-licenses',
          //   name: 'ResourceManagementSchoolsSchoolOrderLicenses',
          //   props: true,
          //   component: () => import(/* webpackChunkName: "Schools" */ '@/views/Default/ResourceOrderLicenses.vue'),
          //   meta: {
          //     title: 'Licenties Bestellen',
          //     showFor: [
          //       {
          //         role: Role.Admin
          //       }
          //     ]
          // }
          // },
          {
            path: 'managers',
            name: 'ResourceManagementSchoolsSchoolManagers',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/SchoolManagers.vue'
              ),
            meta: {
              title: 'Schoolbeheerders',
              showFor: [
                {
                  context: Role.Admin,
                },
                {
                  context: ResourceType.SchoolDistrict,
                },
                {
                  context: ResourceType.Partner,
                },
              ],
            },
          },
          {
            path: 'access-requests',
            name: 'ResourceManagementSchoolAccessRequest',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/AccessRequests.vue'
              ),
            meta: {
              title: 'Toegangsverzoeken',
              showFor: [
                {
                  role: Role.Admin,
                  context: Role.Admin,
                },
              ],
            },
          },
          {
            path: 'settings',
            name: 'ResourceManagementSchoolsSchoolSettings',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/SchoolSettings.vue'
              ) as TODO,
            meta: {
              title: 'Instellingen',
              showFor: [
                {
                  role: Role.Admin,
                  context: Role.Admin,
                },
                {
                  context: ResourceType.Partner,
                },
              ],
            },
          },
        ],
      },
      /** *****************
       *
       *  SCHOOL MANAGERS
       *
       ***************** */
      {
        path: 'management/managers',
        name: 'ResourceManagementManagers',
        component: () =>
          import(
            /* webpackChunkName: "SchoolManagers" */ '@/views/Default/SchoolManagers.vue'
          ),
        meta: {
          title: 'Schoolbeheerders',

          showFor: [
            {
              role: Role.Manager,
            },
            {
              role: Role.Admin,
              context: ResourceType.School,
            },
          ],
        },
      },
      {
        path: 'management/managers/:managerId',
        name: 'ResourceManagementManagersManager',
        component: () =>
          import(
            /* webpackChunkName: "SchoolManagers" */ '@/views/Default/Manager.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementManagersManagerData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementManagersManagerData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolManagers" */ '@/views/Default/ManagerData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'manage',
            name: 'ResourceManagementManagerEdit',
            component: () =>
              import(
                /* webpackChunkName: "SchoolManagers" */ '@/views/Default/ManagerAddEdit.vue'
              ),
            meta: {
              title: 'Schoolbeheerder bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementManagerEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "SchoolManagers" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
        ],
      },
      {
        path: 'manager-add',
        name: 'ResourceManagementManagerAdd',
        component: () =>
          import(
            /* webpackChunkName: "SchoolManagers" */ '@/views/Default/ManagerAddEdit.vue'
          ),
        meta: {
          title: 'Schoolbeheerder toevoegen',
          hideFromMenu: true,
        },
      },
      /** *****************
       *
       *  SCHOOLDISTRICTS
       *
       ***************** */
      {
        path: 'management/schooldistrict-add',
        name: 'ResourceManagementSchooldistrictAdd',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictAddEdit.vue'
          ) as TODO,
        meta: {
          hideFromMenu: true,
          title: 'Schooldistrict Toevoegen',
        },
      },
      {
        path: 'management/schooldistricts',
        name: 'ResourceManagementSchooldistricts',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/Schooldistricts.vue'
          ),
        meta: {
          title: 'Schooldistricten',
          showFor: [
            {
              role: Role.Admin,
              context: Role.Admin,
            },
            {
              context: ResourceType.Partner,
            },
          ],
        },
      },
      {
        path: 'management/schooldistricts/:schooldistrictId',
        name: 'ResourceManagementSchooldistrictsSchooldistrict',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/Schooldistrict.vue'
          ),
        props: true,
        meta: {
          title: 'Schooldistricten',
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementSchooldistrictsSchooldistrictData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementSchooldistrictsSchooldistrictData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'licenses',
            name: 'ResourceManagementSchooldistrictsSchooldistrictLicenses',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/ResourceLicenses.vue'
              ) as TODO,
            meta: {
              title: 'Licenties',
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementSchooldistrictsSchooldistrictEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictAddEdit.vue'
              ) as TODO,
            meta: {
              title: 'Bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'schools',
            name: 'ResourceManagementSchooldistrictsSchooldistrictSchools',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictSchools.vue'
              ),
            meta: {
              title: 'Scholen',
            },
          },
          {
            path: 'schooldistrict-managers',
            name: 'ResourceManagementSchooldistrictsSchooldistrictSchooldistrictManagers',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictManagers.vue'
              ),
            meta: {
              title: 'Schooldistrictbeheerders',
            },
          },
          {
            path: 'settings',
            name: 'ResourceManagementSchooldistrictsSchooldistrictSettings',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictSettings.vue'
              ) as TODO,
            meta: {
              title: 'Instellingen',
              showFor: [
                {
                  role: Role.Admin,
                  context: Role.Admin,
                },
                {
                  context: ResourceType.Partner,
                },
              ],
            },
          },
        ],
      },
      /** *****************
       *
       *  SCHOOL DISTRICT MANAGERS
       *
       ***************** */
      {
        path: 'management/schooldistrict-managers',
        name: 'ResourceManagementDistrictManagers',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagers.vue'
          ),
        meta: {
          title: 'Schooldistrictbeheerders',

          showFor: [
            {
              role: Role.Admin,
              context: ResourceType.SchoolDistrict,
            },
            {
              context: ResourceType.SchoolDistrict,
            },
          ],
        },
      },
      {
        path: 'management/schooldistrict-managers/:schooldistrictManagerId',
        name: 'ResourceManagementSchooldistrictManagersSchooldistrictManager',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManager.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementSchooldistrictManagersSchooldistrictManagerData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementSchooldistrictManagersSchooldistrictManagerData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagerData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'manage',
            name: 'ResourceManagementSchooldistrictManagersSchooldistrictManagerEdit',
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagerAddEdit.vue'
              ),
            meta: {
              title: 'Schoolbeheerder bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementSchooldistrictManagersSchooldistrictManagerEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
        ],
      },
      {
        path: 'schooldistrict-manager-add',
        name: 'ResourceManagementSchooldistrictManagersSchooldistrictManagerAdd',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagerAddEdit.vue'
          ),
        meta: {
          title: 'Schoolbeheerder toevoegen',
          hideFromMenu: true,
        },
      },

      /** *****************
       *
       *  PARTNERS
       *
       ***************** */
      {
        path: 'management/partners',
        name: 'ResourceManagementPartners',
        component: () =>
          import(
            /* webpackChunkName: "Partners" */ '@/views/Default/Partners.vue'
          ),
        meta: {
          title: 'Partners',

          showFor: [
            {
              role: Role.Admin,
              context: Role.Admin,
            },
          ],
        },
      },
      {
        path: 'management/partner-add',
        name: 'ResourceManagementPartnerAdd',
        component: () =>
          import(
            /* webpackChunkName: "Partners" */ '@/views/Default/PartnerAddEdit.vue'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as TODO,
        meta: {
          hideFromMenu: true,
          title: 'Partner Toevoegen',
        },
      },
      {
        path: 'management/partner/:partnerId',
        name: 'ResourceManagementPartnersPartner',
        component: () =>
          import(
            /* webpackChunkName: "Partners" */ '@/views/Default/Partner.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementPartnersPartnerData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementPartnersPartnerData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Partners" */ '@/views/Default/PartnerData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'licenses',
            name: 'ResourceManagementPartnersPartnerLicenses',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Partners" */ '@/views/Default/ResourceLicenses.vue'
              ) as TODO,
            meta: {
              title: 'Licenties',
            },
          },
          {
            path: 'partner-managers',
            name: 'ResourceManagementPartnersPartnerManagers',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Partners" */ '@/views/Default/PartnerManagers.vue'
              ),
            meta: {
              title: 'Partnerbeheerders',
            },
          },
          {
            path: 'partner-settings',
            name: 'ResourceManagementPartnerSettings',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Partners" */ '@/views/Default/PartnerSettings.vue'
              ),
            meta: {
              title: 'Instellingen',
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementPartnersPartnerEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Partners" */ '@/views/Default/PartnerAddEdit.vue'
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ) as TODO,
            meta: {
              title: 'Bewerken',
              hideFromMenu: true,
            },
          },
        ],
      },
      /** *****************
       *
       *  PARTNER MANAGERS
       *
       ***************** */
      {
        path: 'management/partner-managers',
        name: 'ResourceManagementPartnerManagers',
        component: () =>
          import(
            /* webpackChunkName: "PartnerManagers" */ '@/views/Default/PartnerManagers.vue'
          ),
        meta: {
          title: 'Partnerbeheerders',

          showFor: [
            // {
            //   role: Role.Admin,
            //   context: Role.Admin
            // }
            {
              context: ResourceType.Partner,
            },
          ],
        },
      },
      {
        path: 'management/partner-managers/:partnerManagerId',
        name: 'ResourceManagementPartnerManagersPartnerManager',
        component: () =>
          import(
            /* webpackChunkName: "PartnerManagers" */ '@/views/Default/PartnerManager.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementPartnerManagersPartnerManagerData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementPartnerManagersPartnerManagerData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "PartnerManagers" */ '@/views/Default/PartnerManagerData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'manage',
            name: 'ResourceManagementPartnerManagersPartnerManagerEdit',
            component: () =>
              import(
                /* webpackChunkName: "PartnerManagers" */ '@/views/Default/PartnerManagerAddEdit.vue'
              ),
            meta: {
              title: 'Partnerbeheerder bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementPartnerManagersPartnerManagerEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "PartnerManagers" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
        ],
      },
      {
        path: 'partner-manager-add',
        name: 'ResourceManagementPartnerManagersPartnerManagerAdd',
        component: () =>
          import(
            /* webpackChunkName: "PartnerManagers" */ '@/views/Default/PartnerManagerAddEdit.vue'
          ),
        meta: {
          title: 'Schoolbeheerder toevoegen',
          hideFromMenu: true,
        },
      },

      /** *****************
       *
       *  ARCHIVED USERS
       *
       ***************** */
      {
        path: 'management/archived-users',
        name: 'ResourceManagementArchivedUsers',
        component: () =>
          import(
            /* webpackChunkName: "ArchivedUsers" */ '@/views/Default/ArchivedUsers.vue'
          ),
        meta: {
          title: 'Gearchiveerde gebruikers',
          showFor: [
            {
              role: Role.Admin,
              context: Role.Admin,
            },
          ],
        },
      },

      /** *****************
       *
       *  ARCHIVED STUDENTS
       *
       ***************** */
      {
        path: 'management/archived-students',
        name: 'ResourceManagementArchivedStudents',
        component: () =>
          import(
            /* webpackChunkName: "ArchivedStudents" */ '@/views/Default/ArchivedStudents.vue'
          ),
        meta: {
          title: 'Gearchiveerde cursisten',
          showFor: [
            { role: Role.SchoolDistrictManager },
            { role: Role.Manager },
          ],
        },
      },

      /** *****************
       *
       *  MESSAGES
       *
       ***************** */
      {
        path: 'messages',
        name: 'Messages',
        redirect: {
          name: 'MessagesInbox',
        },
        meta: {
          title: 'Berichten',
          badge: 'unreadMessagesCount',
          showFor: [
            {
              role: Role.Coach,
            },
          ],
        },
      },
      {
        path: 'message/:messageId',
        name: 'MessagesMessage',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/Message.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
      },
      {
        path: 'messages/inbox',
        name: 'MessagesInbox',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/MessagesBox.vue'
          ),
        meta: {
          messageBoxType: 'inbox',
          title: 'Inbox',
          badge: 'unreadMessagesCount',
          showFor: [
            {
              role: Role.Coach,
            },
          ],
        },
      },
      {
        path: 'messages/message-new',
        name: 'MessagesMessageNew',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/MessageNew.vue'
          ),
        meta: {
          title: 'Nieuw bericht',
          showFor: [
            {
              role: Role.Coach,
            },
          ],
        },
      },
      {
        path: 'messages/sent',
        name: 'MessagesSent',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/MessagesBox.vue'
          ),
        meta: {
          messageBoxType: 'sent',
          title: 'Verzonden',
          showFor: [
            {
              role: Role.Coach,
            },
          ],
        },
      },
      {
        path: 'messages/archive',
        name: 'MessagesArchive',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/MessagesBox.vue'
          ),
        meta: {
          messageBoxType: 'archive',
          title: 'Archief',
          showFor: [
            {
              role: Role.Coach,
            },
          ],
        },
      },
      {
        path: 'messages/settings',
        name: 'Messages Settings',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/Messages/Settings.vue'
          ),
        meta: {
          title: 'Instellingen',
          showFor: [
            {
              role: Role.Coach,
            },
          ],
        },
      },
      /** *****************
       *
       *  SYSTEM MESSAGES
       *
       ***************** */
      {
        path: 'system-messages',
        name: 'SystemMessagesInbox',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/SystemMessages.vue'
          ),
        meta: {
          title: 'Systeemberichten',
          showFor: [
            {
              role: Role.Admin,
              context: Role.Admin,
            },
          ],
        },
      },
      {
        path: 'system-message/:messageId',
        name: 'SystemMessagesMessage',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/Message.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
          systemMessage: true,
        },
      },
      {
        path: 'system-messages-new',
        name: 'SystemMessagesNew',
        component: () =>
          import(
            /* webpackChunkName: "Messages" */ '@/views/Default/SystemMessageNew.vue'
          ),
        meta: {
          hideFromMenu: true,
          systemMessage: true,
        },
      },
      /** *****************
       *
       *  ADMINISTRATION
       *
       ***************** */
      {
        path: 'administration',
        name: 'Administration',
        redirect: {
          name: 'AdministrationOrganisation',
        },
        meta: {
          title: 'Administratie',
          badge: 'subscriptionExpring_and_amountOfAccessRequest',
          hideFor: [
            {
              role: Role.Coach,
            },
            {
              context: Role.Admin,
            },
          ],
        },
      },
      {
        path: 'administration/organisation',
        name: 'AdministrationOrganisation',
        component: () =>
          import(
            /* webpackChunkName: "Administration" */ '@/views/Default/Resource.vue'
          ) as TODO,
        redirect: {
          name: 'AdministrationOrganisationSchoolData',
        },
        props: true,
        meta: {
          title: 'Organisatie',
          badge: 'amountOfAccessRequest',
          hideFor: [
            {
              role: Role.Coach,
            },
          ],
        },
        children: [
          {
            path: 'data',
            name: 'AdministrationOrganisationSchoolData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/ResourceData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'edit',
            name: 'AdministrationOrganisationSchoolAddEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/SchoolAddEdit.vue'
              ) as TODO,
            meta: {
              title: 'Gegevens bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'access-requests',
            name: 'AdministrationOrganisationAccessRequest',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/AccessRequests.vue'
              ),
            meta: {
              title: 'Toegangsverzoeken',
              badge: 'amountOfAccessRequest',
              hideFor: [
                {
                  context: ResourceType.SchoolDistrict,
                },
                {
                  context: ResourceType.Partner,
                },
              ],
            },
          },
        ],
      },
      {
        path: 'administration/subscription',
        name: 'AdministrationSubscription',
        component: () =>
          import(
            /* webpackChunkName: "Administration" */ '@/views/Default/Resource.vue'
          ) as TODO,
        redirect: {
          name: 'AdministrationSubscriptionSchoolLicenses',
        },
        meta: {
          title: 'Abonnement',
          badge: 'subscriptionExpiring',
          hideFor: [
            {
              role: Role.Coach,
            },
          ],
        },
        children: [
          {
            path: 'licenses',
            name: 'AdministrationSubscriptionSchoolLicenses',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/ResourceLicenses.vue'
              ),
            meta: {
              title: 'Licenties',
            },
          },
          {
            path: 'order-licenses',
            name: 'AdministrationSubscriptionSchoolOrderLicenses',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/ResourceOrderLicenses.vue'
              ) as TODO,
            meta: {
              title: 'Licenties bestellen',
            },
          },
          {
            path: 'manage-subscription',
            name: 'AdministrationSubscriptionSchoolManageSubscription',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/ResourceManageSubscription.vue'
              ),
            meta: {
              title: 'Abonnement beheren',
            },
          },
        ],
      },
      /** *****************
       *
       *  RESULTS
       *
       ***************** */
      {
        path: 'results',
        name: 'ResourceResults',
        component: () =>
          import(
            /* webpackChunkName: "Results" */ '@/views/Default/Results.vue'
          ) as TODO,
        meta: {
          title: 'Resultaten',
          hideFor: [
            {
              role: Role.SchoolDistrictManager,
            },
            {
              context: ResourceType.Partner,
            },
            {
              role: Role.Admin,
              context: ResourceType.SchoolDistrict,
            },
          ],
        },
        redirect: {
          name: 'ResourceResultsLevels',
        },
        children: [
          {
            path: 'levels',
            name: 'ResourceResultsLevels',
            component: () =>
              import(
                /* webpackChunkName: "Results" */ '@/views/Default/ResultsLevels.vue'
              ),
            meta: {
              title: 'Levels',
            },
          },
          {
            path: 'exams',
            name: 'ResourceResultsExams',
            component: () =>
              import(
                /* webpackChunkName: "Results" */ '@/views/Default/ResultsExams.vue'
              ),
            meta: {
              title: 'Examens',
            },
          },
          {
            path: 'participation',
            name: 'ResourceResultsParticipation',
            component: () =>
              import(
                /* webpackChunkName: "Results" */ '@/views/Default/ResultsParticipation.vue'
              ),
            meta: {
              title: 'Participatie',
            },
          },
        ],
      },

      /** *****************
       *
       *  MISC
       *
       ***************** */

      {
        path: 'misc',
        name: 'ResourceMisc',
        component: () =>
          import(/* webpackChunkName: "Misc" */ '@/views/Default/Misc.vue'),
        redirect: {
          name: 'ResourceMiscCoupons',
        },
        meta: {
          title: 'Overige',
          showFor: [
            {
              role: Role.Admin,
              context: Role.Admin,
            },
          ],
        },
        children: [
          {
            path: 'licenses',
            name: 'ResourceMiscCoupons',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Misc" */ '@/views/Default/Coupons.vue'
              ),
            meta: {
              title: 'Coupons',
            },
          },
          {
            path: 'challenge-skills',
            name: 'ResourceMiscChallengeSkills',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Misc" */ '@/views/Default/ChallengeSkills.vue'
              ),
            meta: {
              title: 'Challenge skills',
            },
          },
          {
            path: 'exports',
            name: 'ResourceMiscExports',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Misc" */ '@/views/Default/Exports.vue'
              ),
            meta: {
              title: 'Exporteren',
            },
          },
        ],
      },
    ],
  },
  // {
  //   path: '/coach',
  //   name: 'Coach',
  //   redirect: {
  //     name: 'CoachDashboard'
  //   },
  //   props: true,
  //   component: () => import(/* webpackChunkName: "UserTypeEnvironment" */ '@/views/UserTypeEnvironment.vue'),
  //   children: [
  //     {
  //       path: 'dashboard',
  //       name: 'CoachDashboard',
  //       component: () => import(/* webpackChunkName: "SchoolDashboard" */ '@/views/Coach/Dashboard.vue'),
  //       meta: {
  //         title: 'Dashboard'
  //       }
  //     }
  //   ]
  // },
  /** *****************
   *
   *  MISC
   *
   ***************** */
  {
    path: '*',
    name: 'PageNotFound',
    component: () =>
      import(/* webpackChunkName: "Misc" */ '@/views/PageNotFound.vue') as TODO,
    meta: {
      allowUnauthorized: true,
    },
  },
  {
    path: '*',
    name: 'NotAllowed',
    component: () =>
      import(/* webpackChunkName: "Misc" */ '@/views/NotAllowed.vue') as TODO,
    meta: {
      allowUnauthorized: true,
    },
  },
];

export const router = new VueRouter({
  routes,
  linkActiveClass: 's_active',
  scrollBehavior: (to, from, savedPosition) => {
    // savedPosition is only available for popstate navigations.
    if (savedPosition) return savedPosition;

    // scroll to anchor by returning the selector
    if (to.hash) {
      // specify offset of the element
      // if (to.hash === '#anchor2') {
      //   position.offset = { y: 100 }
      // }
      return { selector: to.hash, behavior: 'smooth' };
    }

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.name === from.name) return {} as TODO;

    // scroll to top by default
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.meta?.allowUnauthorized) return next();

  if (authStore.state === 'unauthenticated') {
    return next({ name: 'Registration' });
  }

  if (authStore.role === Role.DemoStudent || authStore.role === Role.Student) {
    await authStore.logout('cookie');
    return next({ name: 'Login' });
  }

  const resource = {
    resourceId: to.params.resourceId,
    resourceType: to.params?.resourceType?.toUpperCase() as
      | ResourceType
      | Role.Admin,
  };

  logger.getLogger('Router').trace('to.params', to.params);

  const changedPath = to.path !== from.path;
  const changedResource =
    resource.resourceType !== authStore.resourceType ||
    resource.resourceId !== authStore.resourceId;
  const noResource =
    resource.resourceId === 'default' || resource.resourceId === undefined;

  if (changedPath && changedResource && !noResource) {
    await authStore.switchContext({
      resource: {
        resourceId: resource.resourceId,
        resource:
          resource.resourceType === Role.Admin
            ? undefined
            : resource.resourceType,
      },
    });
  }

  return next();
});
