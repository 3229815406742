import { Role } from '@/graphql/types';

export type GqlCountry = {
  code: string;
  label: string;
};

export type GqlProduct = {
  name: string;
  price?: number;
  quantity: number;
  isLicense: boolean;
  code: string;
};

export type GqlLabelizedOrganisationType = {
  type: GqlOrganisationType;
  label: string;
};

export enum GqlOrganisationType {
  'PRIMARY_SCHOOL' = 'PRIMARY_SCHOOL',
  'HIGH_SCHOOL' = 'HIGH_SCHOOL',
  'SCHOOL_DISTRICT' = 'SCHOOL_DISTRICT',
  'REMEDIAL_TEACHING' = 'REMEDIAL_TEACHING',
  'HIGHER_EDUCATION' = 'HIGHER_EDUCATION',
  'OTHER' = 'OTHER',
}

export type GqlBrinSearchResult = {
  province: string;
  competentAuthorityNumber: string;
  brinNumber: string;
  locationNumber: string;
  establishmentName: string;
  streetName: string;
  houseNumberAddition: string;
  postalCode: string;
  placeName: string;
  municipalityNumber: string;
  municipalityName: string;
  denomination: string;
  phoneNumber: string;
  internetAddress: string;
  educationalStructure: string;
  streetNameCorrespondenceAddress: string;
  houseNumberAdditionCorrespondenceAddress: string;
  postalCodeCorrespondenceAddress: string;
  placeNameCorrespondenceAddress: string;
  nodalAreaCode: string;
  nodalAreaName: string;
  rpaAreaCode: string;
  rpaFieldName: string;
  wgrAreaCode: string;
  wgrAreaName: string;
  coropAreaCode: string;
  coropAreaName: string;
  educationalAreaCode: string;
  educationalAreaName: string;
  rmcRegionCode: string;
  rmcRegionName: string;
};

export type GqlCalcLicensesPayload = {
  licenseProduct: GqlProduct;
  serviceProduct: GqlProduct;
  totalPrice: number;
  licensesPrice: number;
  discountPrice: number;
};

export type GqlRequiredContactDetailsInput = {
  companyName?: string;
  contact?: string;
  street: string;
  number: string;
  postalCode: string;
  city: string;
  country: string;
  phone?: string;
};

export type GqlContactDetailsInput = Partial<GqlRequiredContactDetailsInput>;

export type GqlOrganisationUserInput = {
  firstName: string;
  prefix?: string;
  lastName: string;
  email: string;
  username?: string;
};

export type GqlLicenseInfoInput = {
  amount: number;
  startDate: Date;
  couponCode?: string;
};

export type GqlRegisterOrganisationInput = {
  name: string;
  organisationType: GqlOrganisationType;
  address: GqlRequiredContactDetailsInput;
  brinNumber?: string;
  institution?: GqlInstitutionInput;
  email: string;
  copyEmail?: string;
  phone: string;
  chamberOfCommerceNumber?: string;
  requestsBasispoortConnection: boolean;
  invoiceInfo?: GqlContactDetailsInput;
  user: GqlOrganisationUserInput;
  licenseSubscription: GqlLicenseInfoInput;
  acceptsDataProcessingAgreement: boolean;
  acceptsTermsAndConditions: boolean;
  invoiceSelf?: boolean;
  defaultKeyboardLayout?: GqlKeyboardLayout;
};

export type GqlInstitutionInput = {
  id: string;
  dataSource: GqlInstitutionDataSource;
};

export enum GqlInstitutionDataSource {
  'BRIN' = 'BRIN',
  'ONDERWIJS_VLAANDEREN' = 'ONDERWIJS_VLAANDEREN',
}

export type GqlRegisterOrganisationResult = {
  id: string;
  name: string;
};

export type GqlISSStringFilterInput = {
  eq?: string;
  contains?: string;
  in?: string[];
  notIn?: string[];
};

export type GqlInstitutionsWhereInput = {
  id?: GqlISSStringFilterInput;
  name?: GqlISSStringFilterInput;
  street?: GqlISSStringFilterInput;
  streetNumber?: GqlISSStringFilterInput;
  city?: GqlISSStringFilterInput;
  postalCode?: GqlISSStringFilterInput;
  municipality?: GqlISSStringFilterInput;
  province?: GqlISSStringFilterInput;
  country?: GqlISSStringFilterInput;
};

export type GqlPaginatedInstitution = {
  edges?: GqlInstitutionViewModelEdge[];
  pageInfo: GqlPageInfo;
  totalCount: number;
};

export type GqlPageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  endCursor?: string;
};

export type GqlInstitutionViewModelEdge = {
  cursor: string;
  node: GqlInstitution;
};

export type GqlInstitution = {
  id: string;
  name: string;
  street: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  municipality: string;
  province: string;
  country: string;
};

export enum GqlKeyboardLayout {
  'QWERTY' = 'QWERTY',
  'AZERTY' = 'AZERTY',
}

export type GqlDateTime = string;

export type GqlUser = {
  id: string;
  username: string;
  email: string;
  createdAt?: GqlDateTime;
  emailConfirmedAt?: GqlDateTime;
  startDate?: GqlDateTime;
  endDate?: GqlDateTime;
  educationGroup: GqlEducationGroup;
  secondaryEmails: string[];
  firstName: string;
  prefix?: string;
  lastName: string;
  birthDate?: GqlDateTime;
  phone?: string;
  mobile?: string;
  gender?: GqlGender;
  registrationNumber?: number;
  mostRecentLoginDate?: GqlDateTime;
  roles: Role[];
  mostRecentRole?: Role;
  completedGames: GqlGame[];
  keyboardLayout: GqlKeyboardLayout;
  typetuin?: GqlGameUser;
  beheer?: GqlBeheerUser;
  hasExtendedLicense: boolean;
  followingCourseGroup?: GqlCourseGroup;
  coachedCourseGroups: GqlCourseGroupSearchResult;
  mostRecentResource?: GqlMostRecentResource;
  license?: GqlLicense;
  licenseStatus: GqlLicenseStatus;
  students: GqlUsersFilterMessage;
  schools: GqlSchool[];
  schoolDistricts: GqlSchoolDistrict[];
  partners: GqlPartner[];
  note?: GqlNote;
  sentMessages: GqlMessageConnection;
  receivedMessages: GqlMessageConnection;
  unreadMessageCounts: number;
  sentMessagesCount: number;
  lastMessageSentAt: GqlDateTime;
  emailPreferences: GqlEmailPreferences;
  messagePreferences: GqlMessagePreferences;
};

export type GqlGameUser = {
  id: string;
  createdAt: GqlDateTime;
  updatedAt: GqlDateTime;
  startedAt: GqlDateTime;
  userId: string;
  userLevels: GqlUserLevel[];
  kwouwselUpgrades: GqlGameUserKwouwselUpgrade[];
  kwouwselAccessories: GqlGameUserKwouwselAccessory[];
  gamename?: string;
  charactersPerMinuteStandard: number;
  diamonds: number;
  earnedDiamonds: number;
  highestCups: number;
  fontType: GqlFontType;
  focusMode: boolean;
  hideDiamonds: boolean;
  showInterpunct: boolean;
  introductionCompleted: boolean;
  audioMuted: boolean;
  firstSuccessfulTestExam?: GqlChallengeResult;
  lastSuccessfulTestExam?: GqlChallengeResult;
  examRequests?: GqlChallengeRequest[];
  examResults?: GqlChallengeResult[];
  testExamResults?: GqlChallengeResult[];
  hasRequestedDiploma: boolean;
  hasRequestedParticipationCertificate: boolean;
  lastPlay?: GqlChallengeResult;
  displayedAlfredoMessages?: GqlAlfredoMessageGameUser[];
  gameCompleted: boolean;
  gameCompletedDate?: GqlDateTime;
  participationCertificateIsSent: boolean;
  diplomaIsSent: boolean;
  examState: GqlExamState;
  usePersonalLevelSettings: boolean;
  averageAccuracy?: number;
  averageCharactersPerMinute?: number;
  allowedToRequestDiploma: boolean;
  allowedToRequestParticipationCertificate: boolean;
  notDisplayedAlfredoMessages?: GqlAlfredoMessage[];
  practiceTime: GqlPracticeTimeBucket[];
  miniGamesStates: GqlMiniGamesStates;
  examOpeningDayCountDown: number;
  stars?: number;
  amountOfChallengesToCalculateStars?: number;
};

export type GqlChallengeRequest = {
  id: string;
  createdAt: GqlDateTime;
  exercise: GqlExercise;
  challenge: GqlChallenge;
  ignored: boolean;
};

export type GqlChallengeResult = {
  id: string;
  createdAt: GqlDateTime;
  updatedAt: GqlDateTime;
  challengeRequest: GqlChallengeRequest;
  responseTimePerCharacterInMs: GqlJson;
  enteredText: string;
  timeInMs: number;
  durationSinceRequest: number;
  startTime: GqlDateTime;
  accuracy: number;
  charactersPerMinute: number;
  charactersPerMinuteStandard: number;
  passed: boolean;
  cupsEarned?: number;
  diamondsEarned?: number;
};

export type GqlExercise = {
  id: string;
  createdAt: GqlDateTime;
  updatedAt: GqlDateTime;
  level: GqlLevel;
  user: GqlGameUser;
  numberOfChallenges: number;
  extraDiamonds?: number;
  accuracy?: number;
  charactersPerMinute?: number;
  challengeRequests?: GqlChallengeRequest[];
};

export type GqlLevel = {
  id: string;
};

export type GqlUserLevel = {
  id: string;
  firstTime: boolean;
  showedUnlockAnimation: boolean;
};

export type GqlJson = unknown;
export type GqlChallenge = unknown;
export type GqlPracticeTimeBucket = unknown;
export type GqlAlfredoMessageGameUser = unknown;
export type GqlExamState = unknown;
export type GqlAlfredoMessage = unknown;
export type GqlMiniGamesStates = unknown;
export type GqlFontType = unknown;
export type GqlEducationGroup = unknown;
export type GqlGender = unknown;
export type GqlGame = unknown;
export type GqlGameUserKwouwselUpgrade = unknown;
export type GqlGameUserKwouwselAccessory = unknown;
export type GqlBeheerUser = unknown;
export type GqlCourseGroup = { id: string };
export type GqlCourseGroupSearchResult = unknown;
export type GqlMostRecentResource = unknown;
export type GqlLicense = unknown;
export type GqlLicenseStatus = unknown;
export type GqlUsersFilterMessage = unknown;
export type GqlSchool = unknown;
export type GqlSchoolDistrict = unknown;
export type GqlPartner = unknown;
export type GqlNote = unknown;
export type GqlMessageConnection = unknown;

export type GqlID = string;

export type GqlIDFilter = {
  in: GqlID[];
};

export type GqlUserUpdateManyInput = {
  tempPassword?: string;
  startDate?: GqlDateTime;
  endDate?: GqlDateTime;
};

export type GqlUserWhereInput = {
  id: GqlIDFilter;
};

export type GqlAuthBatchPayload = {
  count: number;
};

export type GqlUpdateManyUsersVariables = {
  data: GqlUserUpdateManyInput;
  where: GqlUserWhereInput;
};

export enum GqlEmailFrequency {
  'DAILY' = 'DAILY',
  'WEEKLY' = 'WEEKLY',
  'NEVER' = 'NEVER',
}

export type GqlEmailPreferences = {
  unreadMessagesEmailFrequency: GqlEmailFrequency;
};

export type GqlEmailPreferencesWhereUniqueInput = {
  userId: string;
};

export type GqlEmailPreferencesUpdateInput = {
  unreadMessagesEmailFrequency?: GqlEmailFrequency;
};

export type GqlSendToOrganisationInput = {
  id_in?: string[];
  id_not_in?: string[];
  defaultKeyboardLayout?: GqlKeyboardLayout;
};

export type GqlSendSystemMessageUserInput = {
  keyboardLayout?: GqlKeyboardLayout;
  roles?: Role[];
};

export type GqlSendSystemMessageInput = {
  subject: string;
  content: string;
  users: GqlSendSystemMessageUserInput;
  schools?: GqlSendToOrganisationInput;
};

export enum GqlMessageType {
  'SYSTEM' = 'SYSTEM',
  'AUTOMATED' = 'AUTOMATED',
}

export type GqlRecipient = {
  user?: GqlUser;
  readAt?: GqlDateTime;
};

export type GqlMessage = {
  id: string;
  sender?: GqlUser;
  subject?: string;
  content: string;
  sentAt: GqlDateTime;
  recipients: GqlRecipient[];
  replies: GqlMessage[];
  replyTo?: GqlMessage;
  type: GqlMessageType;
  lastReplyAt?: GqlDateTime;
  archived: boolean;
};

export type GqlMessagePreferences = {
  autoReplyEnabled: boolean;
  autoReplyContent: string;
};

export type GqlMessagePreferencesWhereUniqueInput = {
  userId: string;
};

export type GqlMessagePreferencesUpdateInput = {
  autoReplyEnabled: boolean;
  autoReplyContent?: string;
};

export type GqlMessageCountInput = {
  where?: GqlMessageCountWhereInput;
};

export type GqlTypeEnumFilter = {
  equals?: GqlMessageType;
  in?: Array<GqlMessageType>;
  notIn?: Array<GqlMessageType>;
  not?: GqlTypeEnumFilter;
} | null;

export type GqlNestedMessageCountWhereInput = {
  sentAt?: GqlDateTime;
  type?: GqlTypeEnumFilter;
  AND?: Array<GqlNestedMessageCountWhereInput>;
  OR?: Array<GqlNestedMessageCountWhereInput>;
  NOT?: Array<GqlNestedMessageCountWhereInput>;
};

export type GqlMessageCountWhereInput = {
  sentAt?: GqlDateTime;
  type?: GqlTypeEnumFilter;
  archived?: boolean;
  AND?: Array<GqlNestedMessageCountWhereInput>;
  OR?: Array<GqlNestedMessageCountWhereInput>;
  NOT?: Array<GqlNestedMessageCountWhereInput>;
};
