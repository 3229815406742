// General
export const NODE_ENV = process.env.VUE_APP_NODE_ENV;
export const RELEASE_GIT_SHORT_SHA = process.env.VUE_APP_RELEASE_GIT_SHORT_SHA;
export const APP_VERSION = process.env.VUE_APP_VERSION;

// GraphQL
export const GRAPHQL_HTTP = process.env.VUE_APP_GRAPHQL_HTTP;
export const GATEWAY_URL = process.env.VUE_APP_GATEWAY_URL;
export const FILES_ROOT = process.env.VUE_APP_FILES_ROOT;

// Sentry
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
export const SENTRY_TRACES_SAMPLE_RATE =
  process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE;
export const SENTRY_LOG_LEVEL = process.env.VUE_APP_SENTRY_LOG_LEVEL;
